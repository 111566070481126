@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.icon {
  path {
    fill: colors.$icon-button;
  }
}

.autocomplete {
  width: 300px;
  margin-right: 8px;
}

.editContractPaper {
  width: 688px;

  @include for-mobile {
    width: 360px;
  }
}
