@use 'src/styles/variables/colors';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 24px;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  color: colors.$greyscale-600;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  background-color: colors.$gray;
}

.rateLabel {
  color: colors.$greyscale-900;
}

.periodLabel {
  font-size: 12px;
}

.button {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  padding: 0;
}

.icon {
  width: 16px;
  height: 16px;
  path {
    fill: colors.$secondary;
  }
}
