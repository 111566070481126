@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.twoItemsBox {
  display: flex;
  margin-bottom: 16px;
  gap: 48px;

  @include for-mobile {
    flex-direction: column;
    gap: 24px;
  }
}

.datePickersDash {
  padding: 36px 16px 0;

  @include for-mobile {
    padding: 36px 2px 0;
  }
}
