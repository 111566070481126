@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.selectOptionDescription {
  font-size: 12px;
  line-height: 20px;
  color: colors.$greyscale-600;
}

.pmBox {
  display: flex;
  gap: 48px;
  margin-bottom: 24px;

  @include for-mobile {
    gap: 20px;
  }
}
