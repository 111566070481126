@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.projectInfoCell {
  display: flex;
  align-items: center;

  &Color {
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 12px;
    display: inline-block;
  }

  &ColorCollapse {
    min-width: 16px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-right: 8px;
  }

  &Title {
    margin-right: 12px;
    font-size: 14px;
    font-weight: typo.$bold;
    color: colors.$greyscale-900;
  }

  &Subtitle {
    padding-left: 12px;
    font-size: 12px;
    font-weight: typo.$bolder;
    color: colors.$greyscale-500;
    text-transform: uppercase;
    border-left: 1px solid colors.$greyscale-500;
  }
}

.contractName {
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: colors.$secondary;
}
