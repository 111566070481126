@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.cell {
  &Button {
    height: 34px;
    background-color: transparent;
    font-size: 14px;
    font-weight: typo.$bolder;
    color: colors.$typo-primary;

    &:hover {
      background-color: colors.$divider;
    }

    &.disabled {
      background-color: transparent;
    }
  }

  &Icon {
    width: 16px;
    height: 16px;
    color: colors.$typo-primary;

    & path {
      fill: colors.$greyscale-500;
    }
  }
}

.contractName {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emptyMessage {
  min-height: 40px;
  align-items: center;
}
